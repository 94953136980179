export default {
  data() {
    return {
      list: {
        url: '/canteen/entertain/getAllList',
        method: 'post',
        fields: this.getListFields(),
        action: false
      }
    };
  },
  methods: {
    getListFields() {
      return [
        {
          label: '姓名',
          prop: 'name',
          width: 120,
          headerAlign: 'center',
          align: 'center'
        },
        {
          label: '部门',
          prop: 'department',
          width: 160,
          headerAlign: 'center'
        },
        {
          label: '电话',
          prop: 'telephone',
          width: 160,
          headerAlign: 'center',
          align: 'center'
        },
        {
          label: '预约日期',
          prop: 'orderDate',
          width: 130,
          headerAlign: 'center',
          align: 'center'
        },
        {
          label: '预约类型',
          prop: 'type',
          width: 100,
          headerAlign: 'center',
          align: 'center',
          colors: ['', 'success', 'danger', 'warning'],
          formatter(row) {
            const values = ['', '早餐', '中餐', '晚餐'];
            return values[row.type];
          }
        },
        {
          label: '人数',
          prop: 'num',
          width: 120,
          headerAlign: 'center',
          align: 'center'
        },
        {
          label: '单价',
          prop: 'price',
          width: 120,
          headerAlign: 'center',
          align: 'center',
          color: 'danger',
          formatter(row) {
            return `￥${row.price}`;
          }
        },
        {
          label: '费用',
          prop: 'cost',
          width: 120,
          headerAlign: 'center',
          align: 'center',
          color: 'danger',
          formatter(row) {
            return `￥${row.cost}`;
          }
        },

        {
          label: '审批状态',
          prop: 'auditStatus',
          width: 120,
          headerAlign: 'center',
          align: 'center',
          formatter(row) {
            const values = ['未审批', '审批中', '审批完成', '审批不通过'];
            return values[row.status];
          }
        },
        {
          label: '状态',
          prop: 'status',
          width: 100,
          headerAlign: 'center',
          align: 'center',
          colors: ['', 'primary', 'success', 'info', 'success'],
          formatter(row) {
            const values = ['', '已预约', '已使用', '已取消', '已支付'];
            return values[row.status];
          }
        },
        {
          label: '支付状态',
          prop: 'payStatus',
          width: 100,
          headerAlign: 'center',
          align: 'center',
          colors: ['danger', 'success'],
          formatter(row) {
            const values = ['未支付', '已支付'];
            return values[row.payStatus];
          }
        },
        {
          label: '支付时间',
          prop: 'payTime',
          width: 220,
          headerAlign: 'center',
          align: 'center'
        }
      ];
    }
  }
};
